/**
 * File: styles.css
 * Description : Contains all necessary styling for the Greenlife - Nature & Environmental Non-Profit HTML5 Template
 * Author: xenioushk
 * URL: https://themeforest.net/user/xenioushk
 * Project: Greenlife - Nature & Environmental Non-Profit HTML5 Template
 **/

/*========================================================================*/
/*   TABLE OF CONTENT
/*========================================================================*/
/*
/*      01. GENERAL STYLING
/*      02. HEADER
/*      03. MAIN NAVIGATION MENU
/*      04. HOME PAGE
/*      05. BLOG LAYOUT
/*      06. BLOG SINGLE PAGE
/*      07. CAUSES PAGE
/*      08. ABOUT US PAGE
/*      09. GALLERY PAGE
/*      10. SERVICE PAGE
/*      11. DONATION PAGE 
/*      12. 404 PAGE
/*      13. FOOTER
/*      14. SIDEBAR & WIDGETS
/*      15. RESPONSIVE STYLING
/*
/*========================================================================*/

@import 'variables.less';
@import 'mixins.less';
@import 'reset.less';

/***********************************************************************************************/
/* 01. GENERAL STYLING  */
/***********************************************************************************************/

body {
    font: 18px/28px @body-font;
    color: @default-font-color;
    font-style: normal;
    font-weight: 400;
    position: relative;
}

img{
    max-width: 100%;
}

a {
    color: @primary-color;
    text-decoration: none;   
    .transition(all, 0.3s);
}

a:hover,
a:focus {
    
    color: darken(@primary-color, 20%);
    text-decoration: none;
    
}

blockquote{
    font: @body-font;
    color: @text-color;
}
 
h1, h2, h3, h4, h5, h6 {    
    font-family: @heading-font;
    font-style: normal;
    font-weight: 800;
    color: @heading-font-color;
    line-height: 36px;
    margin: 0 0 16px 0;
}


h1{

    font-size: 56px;

}

h2{

    font-size: 48px;

}

h2+p{
    
    margin-top: 12px;
}


h3{

    font-size: 36px;

}

h3+p{
    
    margin-top: 12px;
}


h4{

    font-size: 24px;

}

h4+p{
    
    margin-top: 12px;
}

h5{

    font-size: 20px;

}

h6{

    font-size: 16px;

}

* + p {
    margin-top: 12px;
}

p {
    margin: 0 0 12px;
    padding: 0;
}

ul, li{

    list-style-type: none;
}

input[type=text],
input[type=password],
input[type=submit],
input[type=button],
textarea{
    
    .round(0);
    .drop-shadow(0, 0, 0, 0, 0);
    
    &:focus{

        .drop-shadow(0, 0, 0, 0, 0);

    }

}

strong{
    font-weight: 700;
}

// PRE LOADER

#preloader {
    background: rgba(255,255,255,1);
    height: 100%;
    position: fixed;
    width: 100%;
    z-index: 9999;
}
#preloader span {
    
    left: 50%;
    margin-left: -15px;
    margin-top: -15px;
    position: absolute;
    top: 50%;
}

// BACK TO TOP

#backTop{
    width:16px;
    height:16px;
    padding:10px;
    border-radius:0px;
    text-indent:-9999px;
    cursor:pointer;
    z-index:999999999;
    display:none;
    box-sizing:content-box;
    -webkit-box-sizing:content-box;
    opacity: 1;
    .transition(opacity, 0.2s);
    &:hover{
        opacity: 1;
    }
}

#backTop:after{
    position: absolute;
    content: '-';
    width: 16px;
    height: 16px;
    text-align: center;
    top: 0;
    left: 0;
    color: #FFF;
}

#backTop.custom{
    
    background-color: @primary-color;
    background-image: url('../images/uparr-48-b.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border: 0px solid lighten(@primary-color, 20%);
     .transition( 0.2s );
    
    &:hover{
         background-color:  rgba(255,255,255,1);
         border: 0px solid darken(@primary-color, 20%);
    }
}

// COMMON UTILITY CLASSES

.text-bold{
    font-weight: 800;
}

.capitalize{
    text-transform: capitalize;
}

.uppercase{
    text-transform: uppercase;
}

.alert,
.progress,
.pre {
    .round(0px);
}

.margin-top-5{
    margin-top: 5px;
}

.margin-top-30{
    margin-top: 30px;
}

.margin-top-40{
    margin-top: 40px;
}

.margin-top-50{
    margin-top: 50px;
}

.margin-top-20{
    margin-top: 20px;
}

.margin-top-11{
    margin-top: 11px;
}

.margin-bottom-5{
    margin-bottom: 5px;
}

.margin-bottom-11{
    margin-bottom: 11px;
}

.margin-bottom-30{
    margin-bottom: 30px;
}

.margin-bottom-50{
    margin-bottom: 50px;
}

.margin-bottom-mob-30{
    margin-bottom: 30px;
}

.margin-bottom-mob-50{
    margin-bottom: 50px;
}

.margin-bottom-80{
    margin-bottom: 80px;
}

.margin-right-0{
    margin-right: 0px;
}

.padding-left-0{
    padding-left: 0px;
}

.padding-right-0{
    padding-right: 0px;
}

.padding-right-5{
    padding-right: 5px;
}

.padding-right-11{
    padding-right: 11px;
}

.no-padding{
    padding: 0px;
}


.inline {
    li {
            display: inline-block;
            margin-right: 24px;

            &:last-child {
                    margin-right: 0;
            }
    }
}


.narrow-p {
	margin: 0 auto 1.5em;

	width: 30%;
}

.fr { float: right;}
.fl { float: left;}
.cb { clear: both;}
.db{display: block;}

.overflow-hidden{
    overflow: hidden;
}

span.border-light-dotted{
    border-bottom: 1px dotted @primary-color;
}

span.border-bold-dashed{
    border-bottom: 2px dashed @primary-color;
}

// BLOCKQUOTE

blockquote {
  padding: 12px 26px 26px 42px;
  margin: 0 0 20px;
  font-size: 14px;
  font-family: @body-font;
  background: @pure-white-bg;
  position: relative;
  border: 1px solid @light-border-color;
  border-left: 4px solid darken(@light-border-color, 50%);
  .transition(0.3s);

    &:hover{
        
        border: 1px solid darken(@light-border-color,15%);
        border-left: 4px solid darken(@light-border-color, 50%);

    }

    p {
        
        font-size: 14px;
        line-height: 24px;
        

    }

}
blockquote:before {
  display: block;
  position: absolute;
  left: 12px;
  top: 14px;
  content: "\f10d";
  font-family: 'FontAwesome';
  font-size: 15px;
  color: #d6d6d6;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
blockquote.testimonial {
  background: @pure-white-bg;
  border-top: 1px solid @light-border-color;
  border-right: 1px solid @light-border-color;
  border-bottom: 1px solid @light-border-color;
  margin-bottom: 0;
}
cite {
  text-align: right;
  display: block;
}
cite:before { 
  margin-right: 4px;
}
.bq-author {
  padding: 16px 0 0 110px;
  position: relative;
}
.bq-author:before {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 69px;
  width: 0;
  height: 0;
  border-left: 22px solid transparent;
  border-right: 0 solid transparent;
  border-top: 22px solid #dcdcdc;
  z-index: 2;
}
.bq-author:after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  left: 70px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 0 solid transparent;
  border-top: 20px solid #fff;
  z-index: 3;
}
.bq-author h6 {
  margin-bottom: 6px;
}
.bq-author .bq-author-info {
  font-size: 12px;
}

// ORDERED/UNORDERED LISTS

ol {

    li {
        padding: 0;
        margin: 0 0 10px 25px;
        list-style-type: decimal;
    }
}


.section-container{
    margin: 24px 0 0 0;
}




.section-heading{
    position: relative;
    font-size: 48px;
	line-height: 48px;
    margin-bottom: 18px;
    text-transform: uppercase;
    font-family: @heading-font-alt;
    font-weight: 800;
    display: inline-block;
    color: @heading-font-color;

            span{
                font-style: 400;
                color: @heading-font-color;
            }

    
}

.section-heading-alt{
    color: @light-text-color;
    
    span{
        font-style: 400;
        color: @light-text-color;
    }
}

.section-subheading{
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    color: @sub-text-color;
    margin-bottom: 0;
    padding: 0px 42px;
    text-transform: uppercase;
}

.section-subheading-alt{
    color: @light-text-color;
}

// ACCORDION

.accordion .panel{
    margin-bottom: 3px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.accordion .panel .panel-title a{
    display: block;
}

span.label {
    margin-right: 1px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
    font-size: 100%;
}

// BUTTONS

#btn,
.btn {
     text-align: center;
    .transition(0.3s);

    &:hover{
        
        color: #000000;
        
    }
    
}


.btn-dynamism{
  .round(0px);
   padding: 12px 42px;  
   
   &:hover{
       background: @primary-color;
       color: @light-text-color;
   }
   
}


.btn-custom{
    
    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 14px;
	font-weight: 700;

    &:hover{
        border-color: darken(@light-border-color,15%);
        color: @primary-color;
        background: @pure-white-bg;
    }
    
}

.btn-custom-inverse{

    text-align: center;
    .transition(0.3s);
    border: 1px solid @light-border-color;
    color: @primary-color;
    background: @pure-white-bg;
    .round();
    padding: 12px 14px;

    &:hover{

        background: @primary-color;
        color: @light-text-color;
    }

}

a.btn-load-more{
	font-size: 22px;
	margin-top: 40px;
	background: @secondary-color;
	border: 2px solid @secondary-color;
	color: @text-color;
	height: 60px;
	line-height: 39px;
	padding-left: 28px;
	padding-right: 28px;
	font-weight: 700;
	.round(3px);

	&:hover{
		background: @primary-color;
		border: 2px solid @primary-color;
		color: @light-text-color;
	}

}


.button{
	display: inline-block;
	.round();
	color:white;
	font-weight: 700;	
	height: 3em;
	line-height: 1em;
	padding: 1em;
 
 }
 
 .small-button{
	display: inline-block;
	.round(0px);
	color:@dark-color;
	font-weight: 700;	
	height: 0;
	line-height: 0;
	padding: 1em 1em;
	margin: 6px 0;
                width: 100%;
 
 }
 
 // Text highlighter.
 
.text-highlighter{
    color: @primary-color;
}
 
.text-highlighter-white{
    color: @light-text-color;
}

#comment_btn{
   
    .round(0px);
        text-align: center;
       .transition(0.3s);
       border: 1px solid @light-border-color;
       .drop-shadow(0, 1px, 1px, 0, 0.1);
       background: transparent;
       color: @text-color;
       padding: 6px 12px;

       &:hover{
           border: 1px solid @primary-color;
           color: @primary-color;
           background: transparent;
       }

}

// ERROR NOTIFICATION

#contact-form .p-errors label{
    color: lightcoral;
}

#contact-form .p-errors input[type="text"],
#contact-form .p-errors input[type="email"],
#contact-form .p-errors textarea{
    border-color: lightcoral;
}

// WordPress Theme Support

.wp-caption {
  text-align: center;
}

.wp-caption .wp-caption-text {
  margin-bottom: 0px;
  font-size: 12px;
  font-style: italic;
  line-height: 34px;
}

.sticky{   
    margin-bottom: 21px;
    background: #FFFFFF;    
}

/*------------------------------ Gallery Caption  ---------------------------------*/

.gallery-caption{}

/*------------------------------ By Post Author ---------------------------------*/

.bypostauthor {}



/***********************************************************************************************/
/* 02. HEADER */
/***********************************************************************************************/
 
.main-header {

    // New

    height: auto;
    width: 100%;
    
    .sticky-wrapper{
        background: rgba(0,0,0,0.4);
    }
    
    .navgiation-wrapper{
        background: @pure-white-bg;
        border-bottom: 1px solid rgba(255,255,255,0.2);
    }

    .top-bar {

        background: @top-bar-bg;
        line-height:  42px;
        color: @light-text-color;
        font-size: 15px;
		font-weight: 700;

        i{
            color: @primary-color;
            margin-right: 5px;
            display: inline-block;
        }

        a{
          color: @light-text-color;
			&:hover{color: @primary-color;}
        }

    }

    .top-phone-no{
        display: inline-block;
        margin-right: 12px;
    }

    .top-email-info{
        display: inline-block;
    }

    a.top-donate-btn{
        background: @primary-color;
        color: @light-text-color;
        padding: 12px 20px;
        font-size: 15px;
        border: 0px solid #FFF;
        text-transform: uppercase;
        font-weight: 700;

        .round(0px);

        i.fa{
            color: @light-text-color;
        }

        &:hover{

            color: @text-color;
            background: @secondary-color;
        }
    }
	
	a.top-join-btn{
        background: @secondary-color;
        color: @text-color;
        padding: 12px 20px;
        font-size: 15px;
        border: 0px solid #FFF;
        text-transform: uppercase;
        font-weight: 700;

        .round(0px);

        i.fa{
            color:@light-text-color;
        }

        &:hover{

            color: @light-text-color;
            background: @primary-color;
        }
    }

    .top-location-info{
        margin-right: 12px;
    }

}

.transparent-header {
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1000;
  
    .sticky-wrapper{
        background: transparent !important;
    }
    
}

// SITE LOGO

.logo {
    margin-top: 32px;
    margin-bottom: 0;
}

.header-container{
    border-bottom: 0px solid @gray-color;
    padding-bottom: 0px; //24px
    box-shadow: 0px 0px 2px -4px rgba(0, 0, 0, 0.2);
    .transition(0.2s);
}

/***********************************************************************************************/
/* 03. MAIN NAVIGATION MENU */
/***********************************************************************************************/


a.logo {
    margin-top: 10px;
    display: inline-block;
    
    img{
        display: block;
    }
}

.navbar-default {
    border: none;
    background: transparent;
    margin-bottom: 0px;
    padding: 12px 0;
    
    
    .navbar-nav {
        margin-top: 10px;
    }
    .navbar-nav > li {
        margin-left: 15px;
    }
    .navbar-nav > li > a {
        color: @text-color;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
		padding: 20px 15px;
    }
    .navbar-nav > li > a:hover {
        color: @primary-color;
    }
    .navbar-nav li.drop {
        position: relative;
    }
    .navbar-nav li ul.drop-down {
        margin: 0;
        padding: 0;
        position: absolute;
        top: 71px;
        left: 0;
        width: 190px;
        visibility: hidden;
        opacity: 0;
        z-index: 3;
        text-align: left;
        .transition(all, 0.3s);
    }
    .navbar-nav li ul.drop-down li {
        list-style: none;
        display: block;
        margin: 0;
    }
    .navbar-nav li ul.drop-down li ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        -ms-transform: rotateX(-90deg);
        -o-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
        top: 0px;
        left: 100%;
        border-bottom: none;
    }
    .navbar-nav li ul.drop-down li a {
        display: inline-block;
        text-decoration: none;
        display: block;
        color: @text-color;
        font-size: 15px;
        padding: 7px 20px;
        text-transform: none;
        font-weight: 700;
        background: @pure-white-bg;
        margin: 0;
        border: none;
    }
    .navbar-nav li ul.drop-down li a:hover {
        color: @primary-color;
    }
    .navbar-nav li ul.drop-down li:hover ul.drop-down.level3 {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        -ms-transform: rotateX(0deg);
        -o-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }
    .navbar-nav li ul.drop-down li:last-child {
        border-bottom: none;
    }
    .navbar-nav li:hover > ul.drop-down {
        visibility: visible;
        opacity: 1;
    }
    
}

header.one-page .navbar-nav > li {
    margin-left: 0;
}
header.one-page .navbar-nav > li > a span {
    color:@primary-color;
}


// STICKY TOP MENU

.stuck {
    position:fixed;
    top:0;    
    left: 0px;
    right: 0px;
    z-index: 99;
    opacity: 1;
    .transition(opacity, 0.3s);
    background: rgba(0,0,0,0.8);
    width: 100%;
    padding: 0px;
    margin: 0 !important;
}

// HOME 1 MENU

.menu-container{
    border: 5px solid rgba(0,0,0,0.5);
    background: rgba(0,0,0,0.8);
    margin-top: 20px;
    padding: 0px 20px;
    .navbar-nav li ul.drop-down {
        top: 66px;
    }
}


/***********************************************************************************************/
/* 04. HOME PAGE */
/***********************************************************************************************/

.hightlight-block{
    background: @second-bg-color;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        top: 0;
        left: 0;
        width: 100%;
        height: 5000px;
        overflow: hidden;
        content: '';
        background: rgba(255,255,255,0.95);
        position: absolute;
    }
    
}

// Home Activity

.highlight-1{
	text-align: center;
    margin-bottom: 20px;
    position: relative;
    
    > i{
        color: @primary-color;
        display: block;
        font-size: 70px;
        margin-bottom: 23px;
    }
    h5{
        color: @heading-font-color;
        font-weight: 700;
        margin-bottom: 18px;
        font-size: 24px;
    }
    p {
        color: @text-color;
		margin-bottom: 40px;
    }
    a {
        color: @light-text-color;
        background: @primary-color;
        padding: 10px 20px;
		.round(3px);
    }
    &:hover a {
        background: @light-border-color;  
    }
}


// Home-2 Activity

 .highlight-2 {
	margin-bottom: 20px;
	position: relative;
    
    .icon-style {
        background-color: @primary-color;
        color: @light-text-color;
        font-family: @heading-font;
        font-size: 45px;
        font-weight: 700;
        height: 149px;
        left: 0;
        line-height: 208px;
        position: absolute;
        text-align: center;
        top: 10px;
        width: 70px;
     }
     
     .content {
       margin-left: 90px;
          h5 {
            color: @heading-font-color;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 18px;
			margin-top: 0px;
          }
          
         p {
            color: @text-color;
          }                    
          
     }
    
}


// SECTION CONTENT BLOCK.

.section-content-block{
    padding: 100px 0 100px 0;
    background: @pure-white-bg;
}

.section-pure-white-bg{
    background: @pure-white-bg !important;
}

.section-secondary-bg{
    background: @second-bg-color !important;
}


// SLIDER 1

#slider_1{
    .item img {
        display: block;
        width: 100%;
        height: auto;
    }


    .slider-content {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        width: 100%;

        h2 {
            font-family: @slider-font;
            font-size: 80px;
            line-height: 85px;
            font-weight: 800;
            letter-spacing: -1px;
            color: @light-text-color;

            span{
                background: rgba(235,70,74,0.8);
                padding: 0 3px;

            }
        }

        h3 {

            font-family: @slider-font;
            font-weight: 800;
            font-size: 28px;
            line-height: 32px;
            letter-spacing: -1px;
            margin-top: 20px;
            color: @light-text-color;
        }

        a.btn-slider {
			font-size: 22px;
            margin-top: 18px;
            background: @secondary-color;
            border: 2px solid @secondary-color;
            color: @text-color;
            height: 60px;
            line-height: 39px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 700;
            .round(3px);

            &:hover{
                background: @primary-color;
                border: 2px solid @primary-color;
                color: @light-text-color;
            }

        }

        a.btn-slider-2 {
			font-size: 22px;
            margin-top: 18px;
            margin-left: 15px;
            background: @pure-white-bg;
            border: 2px solid @pure-white-bg;
            color: @primary-color;
            height: 60px;
            line-height: 39px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 700;
            .round(3px);

            &:hover{
                border:2px solid @primary-color;;
                background: @primary-color;;
                color: @light-text-color;
            }

        }

    }


    .owl-controls{

        .owl-nav div {
			text-align: center;
            color: #fff;
            font-size: 70px;
            line-height: 140px;
            margin-top: -80px;
            opacity: 1;
            position: absolute;
            top: 50%;
            z-index: 1;
            -webkit-transition: all 0.3s ease-in-out ;
            -moz-transition: all 0.3s ease-in-out ;
            transition: all 0.3s ease-in-out ;

            &:hover{
                opacity: 0.7;
            }
        }

        .owl-nav div.owl-prev {
            left: 0px;
            width:72px;
            background: rgba(0,0,0,0.3);
        }

        .owl-nav div.owl-next {
            right: 0px;
            width: 72px;
            background: rgba(0,0,0,0.3);
        }

    }

}

// SLIDER 2

#slider_2{

    
    .item img {
        display: block;
        width: 100%;
        height: auto;
    }


    .slider-content {
        position: absolute;
        top: 60%;
        transform: translateY(-60%);
        width: 100%;

        h2 {
            font-family: @slider-font;
            font-size: 60px;
            line-height: 62px;
            font-weight: 700;
            color: @light-text-color;

            span{
                background: rgba(0,0,0,0.5);
                padding: 3px 6px;

            }
        }

        h3 {

            font-family: @slider-font;
            font-weight: 400;
            font-size: 32px;
            line-height: 32px;
            margin-top: 32px;
            color: @light-text-color;
        }

        a.btn-slider {
            margin-top: 18px;
            background: @primary-color;
            border:2px solid @primary-color;
            color: @light-text-color;
            height: 46px;
            line-height: 30px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 600;
            .round(0px);

            &:hover{
                border:2px solid @primary-color;
                background: darken(@primary-color, 10%);
                color: @light-text-color;
            }

        }

        a.btn-slider-2 {
            margin-top: 18px;
            margin-left: 18px;
            background: @pure-white-bg;
            border:2px solid @pure-white-bg;
            color: @dark-text-color;
            height: 46px;
            line-height: 30px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 600;
            .round(0px);

            &:hover{
                border:2px solid @pure-white-bg;
                background: darken(@pure-white-bg, 10%);
                color: @dark-text-color;
            }

        }

    }
    

    .owl-controls{
            position: relative;
            margin-top: -80px;
            
            .owl-dots{
                
                margin: 24px 0 0 0;
                
                text-align: center;
            
            .owl-dot{
                
                    display: inline-block;
                    padding: 2px;
                    width: 14px;
                    height: 14px;
                    background: transparent;
                    border:1px solid @light-border-color;
                    margin: 0 4px;
                    
                span{
                    display: block;
                }
            }
            
            .active{
                
                span{
                    padding: 2px;
                    width: 8px;
                    height: 8px;
                    background: lighten(@primary-color, 10%);
                }
                
            }
            
            }
            
        }

}

// Jumborton
 
#jumborton_1{
	position: relative;
    .item img {
        display: block;
        width: 100%;
        height: auto;
    }


    .jumborton-content {
        position: absolute;
        top: 50%;
        transform: translateY(-55%);
        width: 100%;

        h2 {
            font-family: @slider-font;
            font-size: 80px;
            line-height: 85px;
            font-weight: 800;
            letter-spacing: -1px;
            color: @light-text-color;

            span{
                background: rgba(235,70,74,0.8);
                padding: 0 3px;

            }
        }

        h3 {

            font-family: @slider-font;
            font-weight: 800;
            font-size: 28px;
            line-height: 32px;
            letter-spacing: -1px;
            margin-top: 20px;
            color: @light-text-color;
        }

        a.btn-slider {
			font-size: 22px;
            margin-top: 18px;
            background: @secondary-color;
            border: 2px solid @secondary-color;
            color: @text-color;
            height: 60px;
            line-height: 39px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 700;
            .round(3px);

            &:hover{
                background: @primary-color;
                border: 2px solid @primary-color;
                color: @light-text-color;
            }

        }

        a.btn-slider-2 {
			font-size: 22px;
            margin-top: 18px;
            margin-left: 15px;
            background: @pure-white-bg;
            border: 2px solid @pure-white-bg;
            color: @primary-color;
            height: 60px;
            line-height: 39px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 700;
            .round(3px);

            &:hover{
                border:2px solid @primary-color;;
                background: @primary-color;;
                color: @light-text-color;
            }

        }

    }

}

// Home Latest News 

.margin-bottom-40 {
    margin-bottom: 40px;
}

.latest{
        
        .latest-title {
              margin-top: 20px;
              margin-bottom: 15px;
              font-size: 24px;
			  font-weight: 700;
              line-height: 32px;
              
                    a {
                      color:  @primary-color;
                      
                        &:hover {
                          color: @heading-font-color;
                        }                      
                    }                 
        }
        
        .latest-details {
              font-size: 14px;
			  font-weight: 600;
              margin-bottom: 20px;
              
            span,    
			a {
                  border-right: 1px solid @dark-text-color;
                  color: #1a1a1a;
                  display: inline-block;
                  line-height: 14px;
                  margin-right: 3px;
                  padding-right: 8px;
                  transition: all 0.3s ease 0s;
                  
                    &:last-child {
                      border-right: medium none;
                      margin-right: 0;
                      padding-right: 0;
                    }
                    
                   &:hover {
                      color: @dark-border-color;
                    }                    
                }              
        }        
}


// Home-2 Latest News

.latest2{
	    background: @pure-white-bg;
	    margin-bottom: 20px;
		
		.date{
			display: inline-block;
			margin-top: 20px;
			padding: 10px 20px;
			background: @primary-color;
			color: @light-text-color;
			font-weight: 600;
		}
	
	    .latest-content{
		     padding: 25px;	
			
			.latest-title {
			  margin-bottom: 15px;
			  font-size: 24px;
			  font-weight: 700;
			  line-height: 32px;

					a {
					  color:  @heading-font-color;

						&:hover {
						  color: @primary-color;
						}
					}                 
		      }


			.latest-details {
				  font-size: 14px;
				  font-weight: 600;

				span,    
				a {
					  border-right: 1px solid @dark-text-color;
					  color: #1a1a1a;
					  display: inline-block;
					  line-height: 14px;
					  margin-right: 3px;
					  padding-right: 8px;
					  transition: all 0.3s ease 0s;

						&:last-child {
						  border-right: medium none;
						  margin-right: 0;
						  padding-right: 0;
						}

					   &:hover {
						  color: @dark-border-color;
						}                    
					}              
			} 
	    }
            
}

// Home-2 Team

.team-block-content {
    text-align: center;
    
     .member-details{
             h4 {
                font-size: 22px;
                font-weight: 700;
                margin: 20px 0 0;
             }
             
             p {
                color: #888888;
                font-size: 14px;
                font-weight: 600;
                line-height: 15px;
            }     
             
        }


}

.team-group-img {
    overflow: hidden;
    position: relative;
    transition: all 0.4s ease 0s;
        img{
         vertical-align: middle 
        }   
}
.team-group-social-icon {
    background: @primary-color;
    padding: 15px 0;
    text-align: center;
    transition: all 0.4s ease 0s;
    
        li {
        display: inline-block;
        
        &:last-child a {
                margin-right: 0;
            }
       }
       
         a {
        border: 1px solid @light-text-color;
        color: @light-text-color;
        display: inline-block;
        height: 30px;
        line-height: 30px;
        margin-right: 10px;
        width: 30px;
        
        &:hover {
            background: @light-text-color;
            transition: all 0.4s ease 0s;
            
            i {
                color: @text-color;
                transition: all 0.4s ease 0s;
            }
        }
        
        i {
            color: @light-text-color;
          }
          
        }  
}



// Home-2 Cause


.cause-wrapper{
    float: left;
    clear: both;
    margin-bottom: 20px;
    background: @pure-white-bg;

    .cause-img{

        display: block;
        position: relative;
        overflow: hidden;

        img{
            margin-bottom: 0px !important;
            display: block;
        }

        &:before{
            position: absolute;
            content: '';
            width: 0%;
            height: 700px;
            background: rgba(0,0,0,0.0);
            top:0 ;
            left: 0;
            transition: all 0.3s ease-in-out ;
        }

    }
	
	.cause-button2{
		font-size: 22px;
		background: @secondary-color;
		border: 2px solid @secondary-color;
		color: @text-color;
		height: 60px;
		line-height: 39px;
		padding-left: 28px;
		padding-right: 28px;
		margin-bottom: 20px;
		font-weight: 700;
		.round(3px);

		&:hover{
			background: @primary-color;
			border: 2px solid @primary-color;
			color: @light-text-color;
		}

	}

    .cause-content{

        padding: 20px 25px;

        .cause-text{

            h5{

                a{
                    font-family: @heading-font-alt;
                    margin-top: 18px !important;
                    color: @heading-font-color;
                    font-size: 24px;
					font-weight: 700;
					line-height: 32px;
                    display: block;
					&:hover {
						  color: @primary-color;
						}
                }
            }

        }

        .progress{
            border-radius: 0;
            box-shadow: none;
            background: #FFF;
            margin-top: 24px;
            margin-bottom: 48px;
            margin-left: -1px;
            margin-right: -1px;
            height: 10px;
            position: relative;
            overflow: visible !important;
            border: 1px solid #CCC;
            padding: 1px;
        }

        .progress-bcm{
            border-radius: 0;
            background: @primary-color;
            height: 6px;
            position: relative;
        }

        span.fund-raised{
            background: #FFF;
            border: 2px solid @primary-color;
            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
            font-size: 12px;
            line-height: 36px;
            position: absolute;
            text-align: center;
            width: 42px;
            height: 42px;
            left: 0px;
            top: -16px;
            .round(100%);

            /* shared with before and after */

            &:after {
                content: '';
            }

            /* top-stacked, smaller arrow */
            &:before {
                content: '';
            }


        }

        .fund-item-text{
            /*text-transform: uppercase;*/
            margin: 12px 0px;
            font-size: 18px;
            text-transform: uppercase;
            clear: both;
            font-weight: 700;
        }

        .fund-raised-text{
            color:  @primary-color;
            text-align: left;
        }

        .fund-goal-text{
            color: @default-font-color;
            float: right;
        }


    }
}

// Newsletter

.wish-newsletter-footer {
	 border-bottom: 1px solid @separator-border;
	 padding-bottom: 70px;
	 margin-bottom: 70px;
     color: @light-text-color;
  
     h3 {
      color: @light-text-color;
      font-size: 24px;
      margin: 0px;
	  font-weight: 700;
    } 
    
    p {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    
    .newsletter-form {
          height: 60px;
          margin-top: 5px;
          overflow: hidden;
          position: relative;
          
             input {
              background: @pure-white-bg;
              border: 0;
              color: @text-color;
              height: 60px;
              padding-left: 13px;
              width: 100%;
			  .round(3px);
            }          

            button {
              background: @primary-color;
              border: 0;
              color: @light-text-color;
			  font-size: 22px;
              font-weight: 700;
              height: 60px;
              position: absolute;
              right: 0;
              top: 0;
              transition: all 0.3s ease 0s;
              width: 150px;
              .round(3px);
                &:hover {
                  background: @secondary-color;
				  color: @text-color;
                }              
            }
          
    }    
       
}

// Cause Section home 1 and home 2.

.section-heading-wrapper{
    margin-bottom: 48px;
}

.home-page-section-heading{
    
    span{
        font-size: 16px;
        font-weight: normal;
        font-style: italic;
        color: lighten(@dark-text-color, 30%);
    }
    
}
.home-page-section-sub-heading{
    font-size: 26px;
    line-height: 32px;
    font-style: italic;
    color: lighten(@dark-text-color, 40%);
}

.btn-heading{
    background: transparent;
    border: 2px solid @primary-color;
    color: @primary-color;
}

.cause-section{
    
    background-image: url("../images/cause_bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: initial;
    position: relative;
    overflow: hidden;

    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
    
    .cause-items{

        .owl-controls{
            margin-top: 22px;
            position: absolute;
            right: 8px;
            top: -16px;

            .owl-nav{
                text-align: center;
            }
            
            .owl-prev{
                display: inline;
                margin: 0 2px 0 0;
            }

            .owl-next{
                display: inline;
                margin: 0 0 0 2px;
            }
            
            i.cause-nav-icon{
                display: inline-block;
                color: @light-text-color;
                background: @primary-color;
                border: 1px solid @pure-white-bg;
                width: 24px;
                height: 24px;
                line-height: 20px;
                .transition(all, 0.5s);
            
                &:hover{
                    
                    color: @dark-text-color;
                    background: rgba(156,190,56,0.5);
                }
                
                
            }
        }

    }
    
    
}


// Home Urgent Cause
.urgent-cause{
    
    width: 100%;
    float: left;
    clear: both;
	color: @light-text-color;

    .cause-button2  {
			font-size: 22px;
            margin-top: 18px;
            background: @secondary-color;
            border: 2px solid @secondary-color;
            color: @text-color;
            height: 60px;
            line-height: 39px;
            padding-left: 28px;
            padding-right: 28px;
            font-weight: 700;
            .round(3px);
		    &:hover{
                background: @primary-color;
                border: 2px solid @primary-color;
                color: @light-text-color;
               }
        }


    .cause-content{

        .cause-text{

            h4{
				font-size: 32px;
				font-weight: 700;
				line-height: 32px;
                color: @primary-color;
				margin-bottom: 5px;
            }
			h2{
			   font-size: 48px;
			   line-height: 60px;
               color: @light-text-color;
			   margin-bottom: 40px;
            }

        }

        .progress{
            border-radius: 0;
            box-shadow: none;
            background: #FFF;
            margin-top: 24px;
            margin-bottom: 48px;
            margin-left: -1px;
            margin-right: -1px;
            height: 17px;
            position: relative;
            overflow: visible !important;
            border: 3px solid @pure-white-bg;
			.round(3px);
        }

        .progress-bcm{
            border-radius: 0;
            background: @primary-color;
            height: 11px;
            position: relative;
        }

        span.fund-raised{
            background: #FFF;
			color: @text-color;
            border: 3px solid @primary-color;
            box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.05);
            font-size: 12px;
            line-height: 44px;
            position: absolute;
            text-align: center;
            width: 50px;
            height: 50px;
            left: 0px;
            top: -16px;
            .round(100%);

            /* shared with before and after */

            &:after {
                content: '';
            }

            /* top-stacked, smaller arrow */
            &:before {
                content: '';
            }


        }

        .fund-item-text{
            margin: 12px 0px;
            font-size: 22px;
            text-transform: uppercase;
            clear: both;
            font-weight: 700;
        }

        .fund-raised-text{
            color:  @primary-color;
            text-align: left;
        }

        .fund-goal-text{
            color: @light-text-color;
            float: right;
        }


    }

}

// Events.

.event-wrapper-1
{

    float: left;
    position: relative;
	
		.ce_event_date {
		background-color: @pure-white-bg;
		color: @primary-color;
		float: left;
		font-size: 15px;
		font-weight: 700;
		position: absolute;
		top: 5px;
	    left: 5px;
		padding: 5px 10px;
		text-align: center;
		.transition(all, 0.2s);
	}
    
    img {
        height: auto;
    }
    
    .ce_event_des_wrap {
        
        .ce_event_new_des {
            float: none;
            padding: 20px 0px 0px 0px;
            width: auto;
            h5 { 
				font-size: 24px;
				line-height: 32px;
				font-weight: 700;
                a {
                    color: @primary-color;
                    display: block;
                    margin: 0 0 15px;
                    &:hover{
                        color: @heading-font-color;
                    }
                }
            }
            ul {
                float: left;
                margin: 0 0 10px;
                width: 100%;
                li {
                    color: @heading-font-color;
                    display: inline-block;
                    font-size: 14px;
		    font-weight: 600;
                    padding: 0 10px;
                    position: relative;
                    list-style: outside none none;
                    &:first-child{
                        padding-left: 0;
                    }
                    a {
                        color: @heading-font-color;
                        display: inline-block;
                    }
                    i {
                        margin-right: 5px;
                    }

                }
            }
        }
    }
    
    &:hover {
		.ce_event_date {
		background-color: @primary-color;
		color: @pure-white-bg;
	    }
    }
    
}

//CTA.

.cta-section{
    
	.cta-section-1{
	    padding: 40px 0;
        background: @primary-color;
	    h2{
			font-size: 32px;
			line-height: 35px;
			color:@light-text-color;
			font-weight: 700;
			margin: 0;
			font-family: @heading-font-alt;
		}
		p{ 
			margin-bottom: 0px;
			font-size: 18px;
			line-height: 20px;
			font-weight: 600;
			color: @light-text-color;
		}

		a.btn-cta3{
			font-size: 22px;
			margin-top: 6px;
			margin-bottom: 6px;
			position: relative;
			background: @primary-color;
			border: 5px solid @pure-white-bg;
			color: @light-text-color;
			height: 60px;
			line-height: 39px;
			padding-left: 28px;
			padding-right: 28px;
			font-weight: 700;
			.round(3px);

			&:hover{
				background: @pure-white-bg;
				color: @text-color;
			}
		}	
	}
	
	.cta-section-2{
		padding: 40px 0;
		background: @secondary-color;

		h2{
			font-size: 32px;
			line-height: 35px;
			color:@text-color;
			font-weight: 700;
			margin: 0;
			font-family: @heading-font-alt;
		}
		p{ 
			margin-bottom: 0px;
			font-size: 18px;
			line-height: 20px;
			font-weight: 600;
			color: @text-color;
		}

		a.btn-cta3{
			font-size: 22px;
			margin-top: 6px;
			margin-bottom: 6px;
			position: relative;
			background: @secondary-color;
			border: 5px solid @text-color;
			color: @text-color;
			height: 60px;
			line-height: 39px;
			padding-left: 28px;
			padding-right: 28px;
			font-weight: 700;
			.round(3px);

			&:hover{
				background: @text-color;
				color: @light-text-color;
			}
		}
	}
	
}



// NEWSLETTER SECTION

.newsletter-area-bg {
    padding: 55px 0;
    background-color: @primary-color;
    color: @light-text-color;
    h3 {
        color: @light-text-color;
        font-size: 24px;
        }
    p {
        margin-top: 20px;
        margin-bottom: 0px;
        }
    .newsletter-form {
    height: 50px;
    margin-top: 28px;
    overflow: hidden;
    position: relative;
        > input {
                background: @pure-white-bg;
                border: 0;
                color: @text-color;
                height: 50px;
                padding-left: 13px;
                width: 100%;
        }
        > button {
                background: @pure-white-bg;
                border-color: -moz-use-text-color -moz-use-text-color -moz-use-text-color #999999;
                border-style: none none none solid;
                border-width: 0 0 0 1px;
                color: @primary-color;
                font-weight: 700;
                height: 50px;
                position: absolute;
                right: 0;
                top: 0;
                transition: all 0.3s ease 0s;
                width: 100px;
                &:hover{
                    background: darken(@pure-white-bg, 10%);
                }  
        }
    }

}


// SECTION TESTIMONIAL BLOCK

.testimonial-block{
    
    background: @primary-color;
    padding:42px;
    
    h4{
         font-size: 30px;
        line-height: 36px;
        color: @light-text-color;
    }
    
    p{
        color: @light-text-color;
    }
    
}

// NEWS BLOCK

.section-news-block{
    
    .news-wrapper{

        background: @pure-white-bg;
        border: 1px solid @light-border-color;

        .news-img{

            display: block;
            position: relative;
            overflow: hidden;

            img{
                margin-bottom: 0px !important;
                width:100%;
                display: block;
            }
            
            .date-meta{
                position: absolute;
                left: 50%;
                bottom: 0;
                display: inline-block;
                width: 60px;
                background: @primary-color;
                font-size: 30px;
                width: 110px;
                text-align: center;
                margin-left: -55px;
                color: @light-text-color;
                padding: 6px 0;
                font-weight: 600;
                
                span{
                    font-size: 16px;
                    display: block;
                }
            }

        }
        
        .news-meta{
            list-style: none;
            overflow: hidden;
            
            li{
                display: block;
            }
            margin: 6px 0;
        }

        .news-button{
            border-radius: 0 !important;
            border: 0px;
            transition: all 0.4s ease 0s;
            background: @primary-color;
            font-size: 14px;
            color: @light-text-color;

            &:hover{
                background:#FFFFFF;
                border: 0px;
                color: @primary-color;

            }

        }

        .news-content{

            padding: 0px 12px 24px 12px;

            .news-text{

                h5{

                    a{
                        font-family: @heading-font-alt;
                        margin-top: 18px !important;
                        color: #444444;
                        font-size: 18px;
                        line-height: 32px;
                        display: block;
                        text-transform: capitalize;
                        font-weight: 400;
                    }
                }

                p{
                    font-size: 16px; 
                    font-weight: normal; 
                }

            }


        }

        // Hover Elements.

        &:hover{

            .news-img{

                &:before{
                    width: 100%;
                    background: rgba(0,0,0,0.5);
                }
            }

            .news-button{
                background: #eb464a;
                color: #FFFFFF;

                .fa{
                    color: #FFFFFF;

                }

            }
        }

    }
    
    
}

//SECTION SHARE

.btn-social-icon {
     height: 42px;
     width: 42px;
     border: 0;
     border-radius: 0px !important;
     margin: 3px 3px;
 }

 .btn-social-icon .fa{
     line-height: 42px;
     color: @light-text-color;
 }

 .btn-goggle-plus {
     background-color: #26b84b;
 }

 .btn-goggle-plus:hover {
     background-color: #20993e;
 }

 .btn-envelope-o {
     background-color: #d74b3e;
 }

 .btn-envelope-o:hover {
     background-color: #ad3d32;
 }

   
//CONTACT SECTION.

.section-contact-block{
       
       position: relative;
       overflow: hidden;
       
       .content-block{
           padding:  0 48px 0 0;
       }
       
       .contact-title{
            font-size: 24px;
		    font-weight: 700;
            border-bottom: 3px solid #eeeeee;
            color: @heading-font-color;
            padding-bottom: 15px;
            position: relative;

            &:after {
                bottom: -3px;
                content: "";
                height: 3px;
                left: 0;
                position: absolute;
                width: 32px;
                background: @primary-color;
            }
           
       }
       
       
       .contact-info{
           
           li{
               margin-bottom: 24px;
           }
           
           .icon-container{
               display: inline-block;
               background: @primary-color;
               color: @light-text-color;
               width: 32px;
               height: 32px;
               line-height: 32px;
               text-align: center;
               margin-right: 16px;
               
               i{
                   font-size: 14px;
               }
           }
           
           address{
               display: inline-block;
           }
           
           a{
               
               color: @default-font-color;
               
               &:hover{
                   color: @primary-color;
               }
               
           }
       }
       
       // Contact Form
       
       .contact-form-block{
           
           padding: 0 0 0 0px;
           
       }
       
       #contact-form{
           
            input[type=text],
            input[type=email],
            input[type=url],
            input[type=password]{
                border: 0px;
                border-bottom: 1px solid @border-color;
                line-height: 42px;
                height: 42px;
                margin-bottom: 24px;
                padding-left: 2px;
                .round(0);
                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                .transition( 0.3s);
                &:focus{

                    .drop-shadow(0,0,0,0);
                    .inner-shadow(0,0,0,0);
                    border: 0px;
                    border-bottom: 1px solid lighten(@dark-border-color,50%);

                }

            }


             textarea{
                border: 0px;
                border-bottom: 1px solid @border-color;
                padding-left: 2px;
                margin-bottom: 24px;
                .round(0);
                .drop-shadow(0,0,0,0);
                .inner-shadow(0,0,0,0);
                .transition( 0.3s);
                &:focus{

                    .drop-shadow(0,0,0,0);
                    .inner-shadow(0,0,0,0);
                    border: 0px;
                    border-bottom: 1px solid lighten(@dark-border-color,50%);

                }

            }

        }
       
       
   }
   
  .section-transparent-bg{
      background: transparent !important;
   }

/***********************************************************************************************/
/* MAIN CONTENT */
/***********************************************************************************************/

.main-content{
    margin: 48px 0;
}


/***********************************************************************************************/
/* 05. BLOG LAYOUT */
/***********************************************************************************************/


// Page Header & Breadcrumb 
.page-header{
    margin: 0;
    padding: 80px 0 80px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-attachment: fixed;
    background-position: 0 50%;
    background-color: transparent;
    background-image: url('../images/header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border: 0px solid #FFF;
    
    &:before{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        top: 0;
        left: 0;
        content: '';
        z-index: -1;
    }
    
    h3{
        color: @light-text-color;
        text-align: center;
        font-size: 36px;
        line-height: 24px;
        font-weight: 700;
        font-family: @heading-font-alt;
        margin: 24px 0;
        text-transform: uppercase;
    }
    
    .page-breadcrumb{
        font-size: 16px;
        color: @light-text-color;
        text-align: center;
        text-transform: uppercase;
        
        a{
            
            color: @light-text-color;
            
            &:hover{
                color: @primary-color;
            }
        }
    }
    
}

.single-post{
    
    margin-bottom: 50px;
    
    p{
        line-height: 30px;
    }
    
    .single-post-content{
        margin-bottom: 30px;
        padding: 6px;
        background: @blog-bg-color;
    }
    
    .single-post-title{
        line-height: 24px;
        
        h2{
            font-size: 24px;
            line-height: 30px;
			font-weight: 700;
            text-transform: uppercase;
        }
        
        .single-post-meta{
            font-size: 12px;
        }
    }
    
}

.readmore{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 12px 24px;
	font-weight: 700;
}

.readmore-sm{
    border:0px;
    background: @primary-color;
    color: @light-text-color;
    .round(0px);
    padding: 6px 12px;
}

/***********************************************************************************************/
/* 06. BLOG SINGLE PAGE */
/***********************************************************************************************/

.single-post-inner{
    background: @blog-bg-color;
    padding: 30px;
}

.post-inner-featured-content{
    display: block;
    
    img{
        display: block;
        width: 100%;
        text-align: center;
    }
}

.single-post-inner-title{
    
    h2{
        font-size: 24px;
		font-weight: 700;
        margin: 32px 0 24px 0;
    }
    margin-bottom: 24px;
}

.single-post-inner-meta{
    
    h2{
         font-size: 24px;
		 font-weight: 700;
    }
    
    .tag-list{
        a{
            display: inline-block;
            padding: 4px 12px;
            margin-right: 3px;
            background: @primary-color;
            color: @light-text-color;
        }
    }
}

.custom-post-info{margin:0; padding:0;}

.custom-post-thumb-small{width: 70px; height: 70px; }
.custom-post-thumb-medium{width: 350px; height: 120px;}

.single-post-container{

    margin: 0 0 24px 0;

    h3{
        margin: 24px 0 12px 0;
        font-size: 18px;
		font-weight: 700;
        text-transform: capitalize;

        a{
            color: @heading-font-color;

            &:hover{
                color: @text-color;
            }
        }
    }
    
    .custom-breadcrumb{
    
        font-size: 18px;        
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid @light-border-color;

    }
    

    .article-meta-extra{
        
        border-top: 1px solid @light-border-color;
        border-bottom: 1px solid @light-border-color;
        padding: 6px 0;
        margin-bottom: 12px;

        span{
        
            margin-right: 5px;

            &:last-child{
                margin-right: 0px;
            }

        }
        
        
        i{
            color: @sub-text-color;
            margin-right: 3px;
        }

    }


    h2+p{

        margin-top: 12px;
    }
 

}

.single-post-content{

    h1{
        margin: 12px 0;
    }

    h2{
        
        margin: 12px 0;

    }

    h3{
        margin: 12px 0;
    }

    h4{

        margin: 12px 0;

    }

    h5{
        margin: 12px 0;
    }

    h6{
        margin: 12px 0;

    }

}

 .articles-nav{
	margin-top: -1.5em;
	margin-bottom: 1.5em;
	padding: 0 2.4em;
 
	.articles-nav-prev{
		float: left;
	}
	
	.articles-nav-next{
		float: right;
	}
	
 }
 

 
 .aligncenter, .alignleft, .alignright, .alignnone {

        margin: 12px 0 24px 0;
        max-width: 100%;
        height: auto; 

 }
 
  .aligncenter, img.centered {
 
	display: block;
	margin: 12px auto;
 }
 

 .alignleft {
	float: left;
 }
 
  .alignright {
	float: right;
 
 }
 
.alignnone {
    clear:both;
}

 .wp-caption{

            text-align:center;

            img{

                max-width: 100%;
                max-height: auto;

            } 

            .wp-cap{
                margin-bottom: 0px;
                font-size: 12px;
                
            }
 }
 
 

 .post-navigation {
 
    text-align:center;
    clear: both;

    p {
    
        text-transform: uppercase;

        a{

            position: relative;
            padding-left: 24px;
            margin-right: 5px;

            &:before{

                content:"";
                position:absolute;
                width:12px;
                height:12px;
                top:4px;
                left:3px;
                background: transparent;
                border: 1px solid @light-border-color;   
                .transition( 0.3s);
            
            }

            &:hover{

                &:before{

                    background: @primary-color;
                    border: 1px solid @text-color;   

                }


            }

            &:first-child{
                margin-right: 0px;
            }

        }

    }

 }



.comments-nav-section,
.post-nav-section{
    margin: 24px 0 0 0;

    p {
        margin-bottom: 0px;
    }

    a{

        .round(0px);
        text-align: center;
       .transition(0.3s);
       border: 1px solid @light-border-color;
       .drop-shadow(0, 1px, 1px, 0, 0);
       background: transparent;
       color: @text-color;
       padding: 6px 12px;

       &:hover{
           border: 1px solid @primary-color;
           color: @primary-color;
           background: transparent;
       }

    }

}

.article-share-section{
    margin: 24px 0 0 0;
    border: 1px solid @light-border-color;
    border-left: 0px;
    border-right: 0px;
    padding: 14px 0 10px 0;
}
 


  .article-author {
  margin-top: 24px;    
  background: @blog-bg-color;
  padding: 30px;
  min-height: 100px;
  
  border-bottom: 1px solid @light-border-color;
  padding-bottom: 24px;

 
    .author-avatar img {
            width: 100px;
            max-width: 100%;
            max-height: auto;
            float: right; 
            border: 1px solid @light-border-color;
            padding: 3px;
            margin-top: 0px;
            margin-left: 12px;
    }
    
    .about_author{
        margin-bottom: 24px;
    }
    
    .social-icons{
        a{
            display: inline-block;
            padding: 8px 10px;
            text-align: center;
            border: 1px solid darken(@light-border-color, 20%);
            color: @default-font-color;
            margin-right: 3px;
            
            &:hover{
                color: @primary-color;
            }
        }
    }


  }
  
  .related-post {
    margin-top: 24px;    
    background: @blog-bg-color;
    padding: 30px;    

    ul {
        
        li {
            margin: 5px 0;
            a {
                
                font-size: 15px;
                position: relative;                      
                .transition( 0.3s);
                color: @default-font-color;

                &:hover {

                    color: @primary-color;

                }


        }


        }
    }
}

.topic-bold-header{
	h4{font-weight: 700;}  
  }

// PAGINATION.



 ul.pagination {

    li {
        
       
        a {
            color: @primary-color;
                .round(0px);
                padding: 6px 12px;
                border: 0px;
                margin: 0 2px;
               
               &:hover{
                   backgroud: #000 !important;
               }
        }
        
        
        a.current{
                    background: @primary-color;
                    color: @light-text-color;
                   border: 0px;
               } 
        

        &:first-child {
            .round(0px);
            a {
                
            }
        }

        &:last-child {
            
                .round(0px);
            a {
            }
        }
    }

 }


// COMMENT LISTS

 .comments-area {

   margin-top: 24px;    
   background: @blog-bg-color;
   padding: 30px;
  
  }
  .article-add-comments {
	float: right;
	display: inline-block;	
	width: 24px;
	height: 24px;
                line-height: 24px;
	text-align:center;
	color:@sub-text-color;		
                border: 1px solid @light-border-color;
                background: @background-color;
                .transition(0.3s);
  
	&:hover {
                    border: 1px solid @primary-color;
                    color: @primary-color;

	}
	
  
  }
  
  .commentslist {
      
	margin-top: 24px;
	counter-reset: comment-id;
              
                li{
                    list-style-type : none;
                
                    margin-left: 0px;

                       &:first-child{

                            margin-top: 24px;

                        }

                }

                

                li+li{
                    margin-top: 24px;
                }

	li ol, li ul {
		padding-left: 3em;
		margin-left: 3em;
		border-left: 1px dotted @light-border-color;
                                list-style-type : none;
	}
	
	li ol li, li ul li{
		position: relative;
		&::before {
			content: '';
			width: 2em;
			height: 1px;
			border-bottom: 1px dotted @light-border-color;
			position: absolute;
			left: -3em;
			top: 2em;
		}
	}
	
	li article {
		background: @background-color;
		padding: 12px;
		
		
		header { 
			border-bottom: 1px solid @light-border-color;
			font-size:12px;
			margin-bottom: 1.5em;
			position: relative;
		
			span {				
				color: lighten(@text-color,30%);
			}
			
			&::before {
				counter-increment: comment-id;
				content: counter(comment-id);
				position: absolute;
				right:5px;
				top: 15px;
				color: @light-color;
				font-size: 32px;
                font-family: @heading-font;
			}
			
			h5 {
                margin-bottom: 12px;
                text-transform: capitalize;
                color: @heading-font-color;
				font-weight: 700;

				a{
					color: @heading-font-color;
					&:hover{
						color: @text-color;
					}
				}     
                                                                
				span {
					font-size: 12px;
					background: @dark-color;					
					color: @light-text-color;
					padding: 3px 5px;
					font-style: normal;
					margin-right:.5em;
					
				}
			}
		
		}

                                .comment_text{
                                    padding-right: 24px;
                                }
	}
  
  }
  

.comment-avatar img {
    width: 64px;
    height: 64px;
        float: right;
        background: #FFFFFF;                
        margin: 2px;
        border: 1px solid @light-border-color;
        padding: 2px;
        .transition(0.3s);


        &:hover{
            border-color: darken(@light-border-color, 15%);
        }
}  

.waiting-moderation {
    color: lighten(@text-color,30%);

}
 
.comments-nav-section{
    margin: 48px 0;
    
    .btn-primary{
        background: @pure-white-bg;
        
        &:hover{
            background: @pure-white-bg;
        }
    }
}


// COMMENT FROM

#comment-form{
    
    label{
        margin-bottom: 12px;
    }

    input[type=text],
    input[type=email],
    input[type=url],
    input[type=password]{
        border: 0px;
        border-bottom: 1px solid @border-color;
        line-height: 42px;
        height: 42px;
        margin-bottom: 24px;
        padding: 0 12px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{
            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            
            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }
    
    
     textarea{
        border: 0px;
        border-bottom: 1px solid @border-color;
        padding: 12px;
        margin-bottom: 24px;
        .round(0);
        .drop-shadow(0,0,0,0);
        .inner-shadow(0,0,0,0);
        .transition( 0.3s);
        &:focus{

            .drop-shadow(0,0,0,0);
            .inner-shadow(0,0,0,0);
            border: 0px;
            border-bottom: 1px solid lighten(@dark-border-color,50%);

        }

    }

}


/***********************************************************************************************/
/* 07. CAUSES PAGE */
/***********************************************************************************************/

.cause-info-container{
    background: darken(@blog-bg-color,2%);
    border: 1px solid @light-border-color;
    margin: 24px 0;
    
    .causes-info-block{
        padding: 24px;
        
        i{
            font-size: 32px;
        }
        
        .btn-donate{
            .round(0px);
            border: 0px;
            padding: 12px 24px;
            color: @light-text-color;
            background: @primary-color;
            font-size: 18px;
            font-weight: 700;
        }
    }
}

/***********************************************************************************************/
/* 07. Events Page */
/***********************************************************************************************/

.event-search-box{
    margin-bottom: 48px; 
    padding: 24px; 
    background: @light-bg;
    border: 1px solid @light-border-color;
    
    
    input{
        border: 0px;
        border-bottom: 1px dotted darken(@light-border-color,20%);
        
        &:focus{
            border-bottom: 1px dotted darken(@light-border-color,50%);
        }
    }
    
    button{
        border-radius: 0px;
    }
    
    .btn-event-search {
    background-color: @primary-color;
    border-color: @primary-color;
    color: #fff;
	font-weight: 700;
        &:hover{
        background-color: darken(@primary-color,10%);   
        }
    }
    
}

.event-content-title{
    font-size: 24px;
    border-bottom: 3px solid #eeeeee;
    color: #5c5c5c;
    padding-bottom: 15px;
    position: relative;

    &:after {
        bottom: -3px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        width: 32px;
        background: @primary-color;
    }

}

.event-sub-content-title{
    margin-top: 12px;
    font-weight: bold;
    display: block;
    
    em.date{
        font-weight: normal;
        border-bottom: 1px dashed darken( @light-border-color, 10%);
        cursor: help;
    }
}

.event-content-info{
    font-size: 14px;
    
    
}

// EVENT SINGLE.

 .article-event {
  margin-top: 24px;    
  background: @blog-bg-color;
  padding: 20px;
  min-height: 100px;
  
  border-bottom:  0px solid @light-border-color;
  padding-bottom: 24px;
  
    .single-post-inner{
        padding: 12px 0px;
    }
  h4{font-weight:700}
  }


/***********************************************************************************************/
/* 07. ABOUT US PAGE */
/***********************************************************************************************/

.about-us-header{
    background-image: url("../images/our_mission_bg.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}


.section-heading-bold{
    
    position: relative;
    font-size: 32px;
    margin-bottom: 48px;
    line-height: 48px;
    
}

.section-how-we-work{
    
    background: @second-bg-color;
    background-image: none;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;

    &:before{
        top: 0;
        left: 0;
        width: 100%;
        height: 5000px;
        overflow: hidden;
        content: '';
        background: rgba(255,255,255,0.95);
        position: absolute;
    }
    
    .service-block-bg{
        overflow: hidden;
        padding: 6px;
        border: 1px solid @light-border-color;
        margin-top: 42px;
        background: darken( @pure-white-bg, 5%);
        .transition(all, 0.3s);
        
        &:hover{
            
             background:  lighten(@primary-color, 30%);
             
             .service-block{
                 background: rgba(255,255,255,1);
                 i.fa{
                     bottom: 20px;
                     font-size: 48px;
                     position: absolute;
                     right: 24px;
                     color:  lighten( @dark-text-color, 40%);
                     opacity: 0.5;
                 }
                 
                 a.service_read_more{
                   color: @primary-color;  
                 }
                 
             }
             
        }
        
    }
    
    .service-block{

        text-align: left;
        //background: rgba(0,0,0,0.8);
        background: rgba(255,255,255,0.9);
        position: relative;
        padding: 24px;
        
        h2{
            font-size: 24px;
            //color: @light-text-color;
            color: @heading-font-color;
            font-family: @heading-font-alt;
            font-weight: 500;
            text-transform: uppercase;
            position: relative;
            
            &:after{
                
                .transition(all, 0.5s);
                position: absolute;
                content: '';
                bottom: -12px;
                left: 0;
                display: block;
                width: 24px;
                height: 2px;
                margin-left: 0px;
                background: @primary-color;
                
            }
            
            
        }
        
        p{
            margin-top: 24px;
        }
        
        a.service_read_more{
            font-weight: 400;
            text-transform: capitalize;
            position: relative;
            color: @dark-text-color;
            display: inline-block;
            
            &:after{
                position: absolute;
                content: '';
                width: 60px;
                height: 1px;
                background: @dark-text-color;
                top: 14px;
                right: -72px;
                
            }
        }
        
        i.fa{
            .transition(all, 0.7s);
            font-size: 48px;
            position: absolute;
            right:  24px;
            bottom: 20px;
            color: @primary-color;
            opacity: 1;
        }
        
         &:hover{
             
             .icon{
                 color: @primary-color;
                 top: 60px;
                 opacity: 1;
             }

            h2{
            
                &:after{
                    
                    background: lighten(@primary-color, 30%);
                    width: 60px;
                    height: 2px;
                }

            }
            
        }
        
    }
    
    .service-img-block{
        
    }
}

.section-our-team{
    
    .team-layout-1{
	background: @pure-white-bg;
        
        .team-member{
            
            a{
                display: block;
                position: relative;
                &:after{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: @primary-color;
                    opacity: 0.01;
                    width: 100%;
                    height: 100%;
                    .transition(all, 0.4s);
                }
            }
            
        }
        
        .team-member img{
           vertical-align: middle;
        }
        
        h3{
            font-family: @heading-font-alt;
            margin: 20px 0 0 0;
            font-size: 22px;
            font-weight: 700;
        }
        
        h4{
            font-family: @heading-font-alt;
            color: @sub-text-color;
            font-size: 14px;
            font-weight: 600;
            
        }
        
        
        p{
         
            color: lighten(@default-font-color, 15%);
            margin: 0;
            font-weight: 400;
            padding-right: 12px;
        }
        
        .team-social-share{
            margin: 15px;
            padding: 20px 10px;
            border-top: 1px solid @light-border-color;
            
            a{
                display: inline-block;
                margin: 0 2px;
                background: @pure-white-bg;
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
            }
            
            
        }
        
        &:hover{
            
            .team-member{

                a{
                    display: block;
                    position: relative;
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        background: @primary-color;
                        opacity: 0.2;
                        width: 100%;
                        height: 100%;
                    }
                }

            }
            
            
            .team-social-share{
                opacity: 1;
                top: 33%;

                a{
                    display: inline-block;
                    margin: 0 2px;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                }


            }
            
        }
        
        
        
    }
    
    
    .team-centered{
        text-align: center !important;
    }
}

//SECTION OUR TEAM 2

.section-our-team{
    
    .team-layout-2{
		background: @second-bg-color;
        margin-bottom: 20px;
		padding-bottom: 15px;
        
        .team-member{
            
            a{
                display: block;
                position: relative;
                &:after{
                    position: absolute;
                    top: 0;
                    left: 0;
                    content: '';
                    background: @primary-color;
                    opacity: 0.01;
                    width: 100%;
                    height: 100%;
                    .transition(all, 0.4s);
                }
            }
            
        }
        
        .team-member img{
           vertical-align: middle;
        }
        
        h3{
            font-family: @heading-font-alt;
            margin: 20px 0 0 0;
            font-size: 22px;
            font-weight: 700;
        }
        
        h4{
            font-family: @heading-font-alt;
            color: @sub-text-color;
            font-size: 14px;
            font-weight: 600; 
        }
        
        
        p{
         
            color: lighten(@default-font-color, 15%);
            margin: 0;
            font-weight: 400;
            padding-right: 12px;
        }
        
        .team-social-share{
            margin: 15px;
            padding: 20px 10px;
            border-bottom: 1px solid @light-border-color;
            
            a{
                display: inline-block;
                margin: 0 2px;
                width: 32px;
                height: 32px;
                text-align: center;
                line-height: 32px;
            }
            
            
        }
        
        &:hover{
            
            .team-member{

                a{
                    display: block;
                    position: relative;
                    &:after{
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: '';
                        background: @primary-color;
                        opacity: 0.2;
                        width: 100%;
                        height: 100%;
                    }
                }

            }
            
            
            .team-social-share{
                opacity: 1;
                top: 33%;

                a{
                    display: inline-block;
                    margin: 0 2px;
                    width: 32px;
                    height: 32px;
                    text-align: center;
                    line-height: 32px;
                }


            }
            
        }
        
        
        
    }
    
    
    .team-centered{
        text-align: center !important;
    }
}

.section-counter{
    
    background-image: url("../images/counter_bg.jpg");
    background-repeat: no-repeat;
    background-position: 0 70%;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
    
    &:before{
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.8);
        content: '';
        width: 100%;
        height: 2000px;
    }
    
    
         .counter-block-1 {
	     margin-bottom: 24px;
          h4 {
              margin-top: 12px;
              font-weight: 700;
              font-family: @heading-font;
              font-size: 22px;
              color: @light-text-color;
              position: relative;
          
                span {
                  color: @primary-color;
                }
            
        }
        

        .counter {
              display: block;
              margin-top: 48px;
              font-size: 55px;
              color: @light-text-color;
              font-weight: 700;
              font-family: @body-font;
        } 
        .icon {
              font-size: 42px;
              display: block;
              margin: 32px 0;
              color: #b3b3b3;
              display: none;
        }
	}
    
}


.section-client-testimonial{
    
    .testimony-layout-1{
        
        position: relative;
        text-align: center;
        
        h6{
            margin: 0;
            font-size: 18px;
            text-transform: uppercase;
			font-weight: 700;
        }
        
        span{
            font-size: 14px;
            text-transform: uppercase;
            color: lighten(@default-font-color, 10%);
        }
        p{
            
            position: relative;
            font-family: @body-font;
            font-size: 20px;
            line-height: 32px;
            margin: 0 0 24px 0;
            color: @default-font-color;
        }
        
        
    }
    
    .testimonial-container{
            
            .owl-controls{
            position: relative;
            margin-top:0px;
            
            .owl-dots{
                
                margin: 24px 0 0 0;
                
                text-align: center;
            
            .owl-dot{
                
                    display: inline-block;
                    padding: 2px;
                    width: 14px;
                    height: 14px;
                    background: transparent;
                    border:1px solid @light-border-color;
                    margin: 0 4px;
                    
                span{
                    display: block;
                }
            }
            
            .active{
                
                span{
                    padding: 2px;
                    width: 8px;
                    height: 8px;
                    background: lighten(@primary-color, 10%);
                }
                
            }
            
            }
            
        }
            
        }
     
    
}


.btn-cta{
    width: 200px;
    font-weight: bold;
    text-transform: uppercase;
}


/***********************************************************************************************/
/* 09. GALLERY PAGE */
/***********************************************************************************************/

.section-gallery-block-parallax{
    
    background-attachment: scroll;
    background-color: transparent;
    background-image: url('../images/bg-geometry.png');
    background-repeat: repeat;
    background-size: initial;
}

.section-gallery-block{
    
}



.gallery-container{
    padding: 12px;
    margin: 0;
    
}

.no-padding-gallery{
    
    .gallery-container{
        padding: 0px;
        margin: 0;

    }
}

.no-bottom-padding{
    padding-bottom: 0px;
}

.gallery-light-box{
    
    display: block;
    overflow: hidden;
    position: relative;
    
    
    
    .gallery-img{
       
        display: block;
        position: relative;
        max-width: 100%;
        padding: 4px 5px;
        
        &:before{
            background: rgba(156, 190, 56, 0.5);
            width: 0%;
            height: 0px;
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            .transition(all, 0.5s);
        }
        
        &:after{
            font-family: FontAwesome;
            content: '\f1c5';
            position: absolute;
            left: 150%;
            margin-left: -21px;
            top: -64px;
            background: @light-text-color;
            color: @primary-color;
            text-align: center;
            width: 42px;
            height: 42px;
            line-height: 42px;
            .round(100%);
            .transition(all, 0.5s);
            
        }
    
        img{
            width: 100%;
            max-width: 100%;
            display: block;
            opacity: 1;
            .transition(all, 0.7s);
        }
        
    }
    
    &:hover{
        
        
        .gallery-img{
            
            &:before{
            background: rgba(156, 190, 56, 0.8);
            width: 100%;
            height: 1500px;
        }
            
            &:after{
                
                opacity: 1;
                top: 50%;
                left: 50%;
                margin-top: -42px;
            }
          
            img{
               
                opacity: 0.4;
            }
            
            figcaption{
                bottom: 0px;
                left: 0;
            }
            
        }
        
        
        
    }
}



/***********************************************************************************************/
/* 10. SERVICE PAGE */
/***********************************************************************************************/

.service-header{
    background-image: url("../images/home_1_banner.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.section-client-logo{
    
    
    .logo-layout-1{

        .logo{

            padding: 12px;
            img{
                .round(0);
                width: 100%;
                margin: 0 auto 24px auto;
                border: 1px solid @light-border-color;
                display: block;
                padding: 12px;
                opacity: 0.7;
                .transition(all, 0.5s);
            }
            
            &:hover{
                img{
                    border: 1px dotted lighten( @primary-color, 10%);
                    opacity: 1;
                }
                
            }
            
        }
        
    }
    
    .logo-layout-2{

        .logo{
            
            background: @pure-white-bg;
            img{
                .round(0);
                width: 100%;
                margin: 0 auto 24px auto;
                border: 1px solid @light-border-color;
                display: block;
                padding: 12px;
                .transition(all, 0.5s);
            }
            
            &:hover{
                img{
                    border: 1px solid lighten( @primary-color, 10%);
                    opacity: 1;
                }
                
            }
            
        }
        
    }
    
    .logo-items{
        
         .owl-controls{
            margin-top: 0px;
            
            .owl-nav{
                text-align: center;
            }
            
            .owl-prev,
            .owl-next{
                display: inline;
                margin: 0 4px;
            }
            i.logo-nav-icon{
                display: inline-block;
                background: transparent;
                border: 1px solid darken( @light-border-color, 10%);
                width: 16px;
                height: 16px;
                position: relative;
                
                &:after{
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    width: 8px;
                    height: 8px;
                    background: lighten(@primary-color, 5%);
                    content: '';
                }
            }
        }
        
    }
    
}
 

/***********************************************************************************************/
/* 11. DONATION PAGE  */
/***********************************************************************************************/

.donation-header{
    background-image: url("../images/donation.jpg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    position: relative;
    overflow: hidden;
}

.donation-form-wrapper{
    border: 1px solid @light-border-color;
    background: darken(@pure-white-bg, 2%);
    padding: 24px;
    
    h4{
        color: @primary-color;
        width: 100%;
        clear: both;
        display: block;
		font-weight: 700;
    }
    
    .form-group{
        margin-bottom: 12px;
        padding: 0;
        float: left;
        clear: both;
        width: 100%;
        label{
            padding-left:0px;
        }
    }
    
    .checkbox{
        clear: both;
    }
    
    input{
        border: 1px solid @light-border-color;
    }
    
    select#country{
        height: 32px;
        line-height: 32px;
        width: 59%;
        border: 1px solid @light-border-color;
        
    }
    
    .btn-submit-donation{
        .round(0px);
        background: transparent;
        color: @primary-color;
        background: @pure-white-bg;
        border: 1px solid @primary-color;
        padding: 12px 24px;
        font-weight: 700;
        text-transform: uppercase;
        .transition(0.3s);

        &:hover{

            color: @light-text-color;
            background: @primary-color;
            border: 1px solid @primary-color;
        }
    }
}

.donation-form-info-1,
.donation-form-info-2,
.donation-form-info-3{
    background: @pure-white-bg;
    border: 1px solid @light-border-color;
    padding: 12px;
    margin: 12px 0;
}

/***********************************************************************************************/
/* 12. 404 PAGE */
/***********************************************************************************************/

.section-404{
    background: @second-bg-color;
}

.message-container-404{

    margin: 48px 0;

    .text-404{
        font-size: 170px;
        font-weight: 600;
        font-family: @heading-font-alt;
        text-align: center;
        margin:80px 0;
        color:@primary-color;

        span {
            color: darken(@primary-color,10%);
        }
    }

    .search-form-404 {

        margin: 0 auto;
        padding: 5px 5px;    
        width: 40%;

    }
    

    .message-text-404{
        padding: 24px 160px;
        display: block;
        text-align: center;

    }

}



/***********************************************************************************************/
/* 13. FOOTER */
/***********************************************************************************************/

.footer-widget-area-bg {
    background: @bottom-bg;
    
    .footer-widget-area{
        .footer-logo{
            margin-bottom: 25px;
        }
        color: @light-text-color;
        > h3 {
            font-size: 24px;
			font-weight: 700;
            color: @light-text-color;
			margin-bottom: 30px;
        }
        a {
            color: @light-text-color;
            display: inline-block;
        }

        .divider-line {
            border-bottom: 1px solid @separator-border;
            margin-bottom: 20px;
            padding-bottom: 20px;
        }
        .contact-heading {
            display: block;
            float: left;
            margin-right: 10px;
            overflow: hidden;
            width: 40px;
        }
        .contact-details {
            color: @light-text-color;
            display: block;
            overflow: hidden;
            .social-icons{
                a {
                    border: 2px solid #f1f1f1;
                    color: @primary-color;
                    display: inline-block;
                    font-size: 16px;
                    margin-right: 10px;
                    text-align: center;
                    i {
                        height: 30px;
                        line-height: 30px;
                        width: 30px;
                    }
                    &:hover{
                        border: 2px solid @primary-color;
                        background: @primary-color;
                        color: @light-text-color;
                    } 
                }
                a.margin-right-0{
                    margin-right: 0px;  
                }
            }
        }
        .color-primary{
            a {
                color: @primary-color;
                &:hover{
                    color: darken(@primary-color, 10%);
                }    
            }
        } 
        .footer-useful-links {
            a {
                line-height: 32px;
				i{color: @primary-color}
                &:hover{
                    color: @primary-color; 
                }
            }
        }

    }
}

.footer-section {
    
    background: @footer-bg;
    border-top: 1px solid #444444;
    padding: 34px 0 36px;
    color: @footer-text-color; 
    span {
       a.color-primary {
        color: @primary-color;
            &:hover{
               color: darken(@primary-color, 10%);
            }
        }
    }
    
    .footer-nav{
        
        clear: both;
        
        ul {
        text-align: center;
        list-style: outside none none;
        margin: 0;
        margin-bottom: 10px;
        padding: 0;
            li {
            
                position: relative;
                line-height: 14px;
                margin-left: 15px;
                padding-left: 15px;
                display: inline-block;
                
                &:after{
                    background: @primary-color;
                    position: absolute;
                    content: '';
                    top: 7px;
                    right: -20px;
                    width: 4px;
                    height: 4px;
                }
				
				&:first-child {
				margin-left: 0px;
                padding-left: 0px;	
				}
                
                &:last-child {
                    
                    &:after{
                        background: transparent;
                        position: absolute;
                        content: '';
                        top: 7px;
                        right: -20px;
                        width: 4px;
                        height: 4px;
                    }
                    
                }
            }
        }
        a {
        color: @footer-text-color;
            &:hover{
               color: @primary-color; 
            }
        }
    }
    
}

/***********************************************************************************************/
/* 14. SIDEBAR & WIDGETS */
/***********************************************************************************************/

.widget{
    background: @blog-bg-color;
    margin-bottom: 30px;
    padding: 20px;
}

.widget-title {
    border-bottom: 3px solid #eeeeee;
    color: @heading-font-color;
    font-size: 24px;
	font-weight: 700;
    padding-bottom: 15px;
    position: relative;
    
    &:after {
        bottom: -3px;
        content: "";
        height: 3px;
        left: 0;
        position: absolute;
        width: 32px;
        background: @primary-color;
    }

}

.widget ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.widget ul li {
    padding: 5px 0;
}
.widget ul li a {
    
    color: @default-font-color;
    
    &:hover{
        color: @primary-color;
    }
}

// Search Widget.

.search-form {
 
    margin: 24px 0 12px;

    span.input-group-addon{
 
        .round(0px);
        background: @primary-btn-bg;
        border-right: 0px;
        color: @light-text-color;
        font-weight: bold;
        width: 10%;
        padding: 0 20px;

    }

    input[type="text"] {
        box-shadow: 0;
        
        margin-bottom:0;        
        font-size: 14px;
        padding-left: 5px;
        height: 42px;
        border: 0px;
        box-shadow: none;
        border-bottom: 1px solid @border-color;
        width: 100%;
        .transition(0.3s);
        &:focus{
            box-shadow: none;
            border-bottom: 1px solid darken(@border-color,10%);
        }
        
    }

}

// Category.

.widget-post-category {

   margin: 11px auto;

    li {
        margin: 5px 0;
        padding: 0;
        text-transform: capitalize;

        a {

            font-size: 15px;
            position: relative;                   
            .transition( 0.3s);

        }

    }

}

// Recent Posts.

.single-recent-post {
    
    margin-bottom: 26px;
    overflow: hidden;
    
    a {
        color: @default-font-color;
        margin-bottom: 8px;
        display: block;
        &:hover{
            color: @primary-color;
        }
        
    }
    
    span {
        font-size: 12px;
        display: block;
        color: @primary-color;
    }
    
}

.single-post-thumb {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
    margin-top: 5px;
    width: 100px;
}

// Tags.

 .widget-recent-tags {
    
    margin: 0 auto;

    li {
         margin: 0px 5px 7px 0px;
         float: left;

        a{
                           
            padding: 3px 7px;
            text-transform: capitalize;
            text-align: center;           
            border: 1px solid @primary-color;
            display: block;
            background: transparent;
            .transition(all, 0.3s);

            &:hover,
            &:active {                
                background: transparent;
                border: 1px solid @primary-color;
                background: @pure-white-bg;
            }

       }

    }

} 

/***********************************************************************************************/
/* Widget Link Color */
/***********************************************************************************************/

.sidebar-widget{

    a{

        color: @text-color;
        .transition(0.3s);
        &:hover{
            color: @primary-color;

        }

    }

}


/***********************************************************************************************/
/* CALENDAR WIDGET */
/***********************************************************************************************/

#calendar_wrap {

    margin: 0 auto 5px auto;

    #wp-calendar {

            width: 100%; 
            
            border: 1px solid @light-border-color;
            padding: 2px;

            caption {
 
                border-color: @light-border-color;
                border-image: none;
                border-style: solid solid none;
                border-width: 1px 1px medium;
                font-weight: bold;
                padding: 10px 0;
                text-align: center;
                text-transform: uppercase;
            }

            thead { 
                
                border-right: 1px solid @light-border-color;

            }
            
            thead th {
                border-right: 1px solid @light-border-color;
            }

            th {
                background: none repeat scroll 0 0 #F9F9F9;
                border-bottom: 1px solid @light-border-color;
                border-right: 1px solid @light-border-color;
            }
            tr th, thead th {
                font-weight: bold;
                padding: 9px 0;
                text-align: center;
            }

            tbody {
                color: #aaa; 

                tr td {
                    border-bottom: 1px solid @light-border-color;
                    border-right: 1px solid @light-border-color;
                    padding: 6px;
                    text-align: center;
                    background: #f5f5f5; 
                    .round(0px);
                    text-align: center;                     
                    .transition(0.3s);

                    &:hover{
                        background: @light-bg;
                    }
                }

                .pad {
                    background: none; 
                }
            }

            tfoot{

                tr td {
                    border-bottom: 1px solid @light-border-color;
                    border-right: 1px solid @light-border-color;
                    padding: 6px;
                    text-align: center;
                }

                #next { 
                        font-size: 12px; 
                        text-transform: uppercase; 
                         text-align: center;
                }
                #prev { 
                     text-align: center;
                    font-size: 12px; 
                    text-transform: uppercase; 
                    padding-top: 10px; 
                }
            }

            #today {
                background-color: @primary-color;
                color: @light-text-color;
                font-weight: bold;
            }

    }

}

/***********************************************************************************************/
/* 15. RESPONSIVE STYLING */
/***********************************************************************************************/

/* Tablet Portrait size to standard 960 (devices and browsers) */
@media only screen and (max-width: 991px) {

    .top-bar{
        text-align: center;
    }
    
    .top-donation-btn-container{
        text-align: center;
        margin-top: 12px;
        margin-bottom: 24px;
    }
    
    a.logo{
        text-align: center;
    }
    
    .navbar-default {
    
        .navbar-nav li ul.drop-down{
            top: 68px;
        }

        .navbar-header{
            width: 100%;
            text-align: center;
            clear: both;
            float: none ;
            margin: 0 ;
        }

        .navbar-toggle{
            margin: 22px 0 0 0;
            padding: 5px;

            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-right {
            float: none;
            padding:0;
            margin: 12px 110px 0 0;
        }

        .navbar .navbar-collapse {
            text-align: center;
        }
    
    }
    
    .top-location-info{
        display: block;
        text-align: center;
    }
    
    .main-menu-container{

        float: none;

    }
    
    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }
    
    
    .navbar-nav > li {
        margin-left: 0;
    }
    .nav > li > a {
        padding: 15px 12px;
        padding-bottom: 22px;
    }
    header.one-page .navbar-nav > li > a {
        padding: 15px 5px;
        font-size: 15px;
        padding-bottom: 22px;
    }
    
         // HOME#1 SLIDER
    
    #slider_1{

        .slider-content {
			
            h2 {
                font-size: 55px;
                line-height: 60px;
            }

            h3 {
				margin-bottom: 10px;
            }
        }
    }
    
    // SLIDER 2

    #slider_2{
        
        text-align: center;
        
        .slider-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            h2 {
                font-size: 48px;
                line-height: 62px;
            }

            h3 {

                font-size: 24px;
                line-height: 32px;
            }

        }

    }
	
	#jumborton_1{

        .jumborton-content {
			
            h2 {
                font-size: 55px;
                line-height: 60px;
            }

            h3 {
				margin-bottom: 10px;
            }
        }
    }
	
	.highlight-block{
		margin-bottom: 25px;
	}

    .main-sidebar{
        width: 100%;
        border: 0px solid transparent;
    }

    .logo{

        text-align: center;
    }
    
    .about_couter_block{
        margin: 48px 0;
    }

    .activity-section-1{

        .activity-info-block{
            padding: 90px 0 90px 90px;
            
            p{
                max-width: 662px;      
              }
        }

    }
    
    .page-header{
    
        padding: 80px 0;
    
    }
    
    
    .event-search-box{
        input[type=text] {
            margin-bottom: 12px;
        }
    }
    .event_wrapper{
        
        margin-bottom: 48px;

        .event-img{

            img{
           
                width: 100%;
                max-width: 100%;
            }

        }

    }
	
	.cta-section{
		.cta-section-1{
                    .text-left,
                    .text-right{
                        text-align: center;
                    }
			a.btn-cta3{
				margin-top: 25px;
			  }

		}

		.cta-section-2{
                    .text-left,
                    .text-right{
                        text-align: center;
                    }
			a.btn-cta3{
				font-size: 18px;
				margin-top: 25px;
			  }

		}
	}
    
    .footer-section{
        .footer-nav{
            margin-top: 24px;
            ul{
                float: none;
                
                li{
                    &:first-child{
                        margin-left: 0px;
                        padding-left: 0px;
                    }  
                }
            }
        }    
    }
    

    .copyright-text{
        text-align: center;
    }
    
    .footer-widget-area-bg{
        padding: 48px 0 24px 0;
    }
    
    .footer-widget-area {
    
      padding: 0px 0 32px 0;
        
    }
	
	.wish-newsletter-footer {
	 padding-bottom: 70px;
	}
    
}

/* All Mobile Sizes (devices and browser) */
@media only screen and (max-width: 767px) {

    .top-bar{
        text-align: center;
    }

    .top-location-info{
        display: block;
        text-align: center;
    }

    .main-menu-container{

        float: none;

    }
    
     .section-content-block{
        padding: 60px 0;
    }
	
	.no-bottom-padding{
		padding-bottom: 0px;
	}
    
     // HOME#1 SLIDER
    
    #slider_1{

        .slider-content {

            text-align: center;

            h2 {
                font-size: 26px;
                line-height: 35px;
            }

            h3 {

                font-size: 16px;
				line-height: 22px;
				margin-bottom: 5px;
            }
			
			a.btn-slider {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}
			
			a.btn-slider-2 {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}


        }

    }
    
    // SLIDER 2

    #slider_2{

        .slider-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

            h2 {
                font-size: 32px;
                line-height: 48px;
            }

            h3 {
                margin-top: 12px;
                font-size: 20px;
                line-height: 32px;
            }

        }
        
        .owl-controls{
          display: none;  
        }

    }
	
	// Jumborton 1
	
	#jumborton_1{

        .jumborton-content {

            text-align: center;

            h2 {
                font-size: 26px;
                line-height: 35px;
            }

            h3 {

                font-size: 16px;
				line-height: 22px;
				margin-bottom: 5px;
            }
			
			a.btn-slider {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}
			
			a.btn-slider-2 {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}


        }

    }
	
	    // Cause
	.cause-wrapper{
		.cause-button2  {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}
		.cause-content{

			.fund-item-text{
				font-size: 15px;
			}

		}

	}
    
    .stuck{
        position: initial;
    }
	
	    // Home 3.
	
	.cta-section{
		.cta-section-1{
			h2{
				font-size: 26px;
				line-height: 34px;
			}
		}

		.cta-section-2{
			h2{
				font-size: 26px;
				line-height: 34px;
			}
		}
    }
    
    .navbar-default {

        .navbar-toggle {
            margin-top: 24px;
            .round(0px);

            span.icon-bar{
                color: @primary-color;
            }
        }

        .nav > li > a,
        header.one-page .navbar-nav > li > a {
            padding: 5px 15px;
        }

        .navbar .navbar-right {
            width: 100%;
            text-align: left;
        }

        .navbar-collapse{
            background: #0d0d0d;
            margin-top: 12px;
            padding: 12px 0;
        }

        .navbar-nav li ul.drop-down,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 {
            background: transparent;
            border: none;
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -ms-transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            transform: rotateX(0deg);
            position: relative;
            width: auto;
            visibility: visible;
            opacity: 1;
            top: inherit;
            left: inherit;
        }

        .navbar-nav li ul.drop-down li a,
        .navbar-nav li ul.drop-down li ul.drop-down.level3 li a {
            background: none;
            color: @light-text-color;
            font-size: 16px;
            text-transform: inherit;
            padding: 4px 20px;
        }

        .navbar-nav li ul.drop-down li a:hover,
            .navbar-nav li ul.drop-down li ul.drop-down.level3 li a:hover {
            color: @light-text-color;
        }

        .navbar-nav{

            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }
    
    .logo{
        text-align: center;
    }
    
    .main-header {
        background: @dark-bg;
        position: relative;
    }

    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }
    
    .about_couter_block{
        margin: 48px 0;
    }
    
    .page-header{
    
        padding: 50px 0;
    
    }
    
    // 404 PAGE.
    
    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }
    
    // Donation Form.
    
    .donation-form-wrapper{
        .form-group{
            div.col-sm-10{
                padding: 0px;
            }
        }
    }
    
    .article-container-fix,
    .main-sidebar {
        width: 100%;
        margin-left: 0px;
        border: 0px solid transparent;
    }
    
    .post-details-container{
         h3 {

            margin-top: 12px;

        }
    }

    .post-box-container {

         .bd-post-image-container{

                margin: 0 auto;

            }

     }

     .image_gallery_carousel{

            margin-bottom: 48px;
    }
    
    
    .footer-nav{
        float: none;
        width: 100%;
        margin: 10px auto;
        display: inline-block;
        text-align: center;
    }

    .copyright-text{
        text-align: center;
    }
    
    //event home 2   
    .event_wrapper_2
	 {
        .ce_event_des_wrap {
            .ce_event_date {
                padding: 20px 10px;
                width: 100%;

            }
            .ce_event_new_des {
                padding: 20px 0 0 0px;
                width: auto;
                clear: both;             
            }
        }
    }
    
    .wish-newsletter-footer {
    .newsletter-form {
        margin-top: 20px;
    }}
    
}

/* Mobile Portrait Size to Mobile Landscape Size (devices and browsers) */
@media only screen and (min-width: 0px) and (max-width: 479px) {
    
    .main-header {
        background: @dark-bg;
        position: relative;
    }


    .sticky-wrapper{
        background: rgba(0,0,0,0.8);
    }

    .top-bar{
        text-align: center;
    }
    
    .top-location-info{
        display: block;
        text-align: center;
    }
    
    .main-menu-container{

        float: none;

    }
    
     a.logo{
        text-align: center;
        width: 75%;
    }

    .navbar-default {
        
        .navbar-toggle{
            margin: 20px 0 0 0;
            padding: 5px 3px;

            span.icon-bar{
                height: 1px;
            }
        }

        .navbar-nav{
            margin:0px;
            width: 100%;
            text-align: left;
            background: rgba(0,0,0,0.7);

            li{
                margin-left: 0px;
                
                a{
                    font-weight: bold;
                    color: @primary-color;
                }
            }

            .drop-down{

                li{
                    a{
                        color: @light-text-color;
                    }
                }


            }
        }

    }

    .logo{
        text-align: center;
    }
   
    
    .section-heading-wrapper{
        margin-bottom: 0px;
    }
    
    .section-content-block{
        padding: 32px 0;
    }
	
	.no-bottom-padding{
		padding-bottom: 0px;
	}
    
    .section-heading{
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
        
        &:after,
        &:before{
          display: none;
        }
    }
    
    .section-subheading{
        display: none;
    }
    
    .page-header{
    
        padding: 30px 0;
    
    }
    
    // Home 1 Slider
    
    #slider_1{
        
        .slider-content {
          
            h3,
            a.btn-slider,
            a.btn-slider-2{
                display: none;
            }
            
        }
        
    }
    
    // Home 2 Slider 
    
     #slider_2{
        
        .slider-content {
          
            h2{
                
                font-size: 24px;
                text-align: center;
            }
            
            h3,
            a.btn-slider,
            a.btn-slider-2{
                display: none;
            }
            
        }
        
    }
    
	// Jumborton 1
    
    #jumborton_1{
        
        .jumborton-content {
          
            h3,
            a.btn-slider,
            a.btn-slider-2{
                display: none;
            }
            
        }
        
    }
	
		
	a.btn-load-more{
		font-size: 18px;
		padding-left: 15px;
		padding-right: 15px;
	}
	
	.cta-section{
		.cta-section-1{
			a.btn-cta3{
				font-size: 18px;
				margin-top: 25px;
				padding-left: 15px;
				padding-right: 15px;
			  }

		}

		.cta-section-2{
			a.btn-cta3{
				font-size: 18px;
				margin-top: 25px;
				padding-left: 15px;
				padding-right: 15px;
			  }

		}				
	}
	
	// Home Urgent Cause
	.urgent-cause{
		.cause-button2  {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}
		.cause-content{

			.cause-text{
				h4{
					font-size: 25px;
				}
				h2{
					font-size: 35px;
					line-height: 45px;
				}
			}
			.fund-item-text{
				font-size: 15px;
			}

		}

	}
	
    // Cause
	.cause-wrapper{
		.cause-button2  {
				font-size: 18px;
				padding-left: 15px;
				padding-right: 15px;
			}
		.cause-content{

			.fund-item-text{
				font-size: 13px;
			}

		}

	}
	
    // Service Block.
    
    .section-how-we-work{
    
        .service-block-bg{
            margin-top: 0px;
            margin-bottom: 24px;

            .service-block{

                a.service_read_more{

                    &:after{
                       display: none;

                    }
                }

            }

        }

    }
    
    //Section Counter.
    
    .section-counter{
        padding: 32px 0 0;
        .counter-block-2 {
            margin: 12px 0 48px;
        }
    }
    
    // Client Logo.
    
    .logo-layout-1,
    .logo-layout-2{

        .logo{
            
            margin-top: 0px;
            
        }
        
    }
    
    //News Block.
    
    .section-news-block {
        
        .news-wrapper{
            margin: 0 0 48px 0;
        }
        
    }
    
    // Home 3.
    
	.cta-section{
		.cta-section-1{
			h2{
				font-size: 25px;
				line-height: 32px;
			}
		}

		.cta-section-2{
			h2{
				font-size: 25px;
				line-height: 32px;
			}
		}
	}
    
    // Page Header.
    
    .page-header{
        h3{
            font-size: 24px;
            line-height: 32px;
            margin: 12px 0;
        }
    }
    
    // 404 PAGE.
    
    .message-container-404{

        margin: 48px 0;

        .text-404{
            margin:80px 0;
        }

        .search-form-404 {
            padding: 5px;    
            width: 100%;
        }

        .message-text-404{
            padding: 24px 0px;
        }

    }
    
    .footer-widget-area {
    
      padding: 0px 0 32px 0;
        
    }
	
	.wish-newsletter-footer {
	 padding-bottom: 70px;
	}
    
    .footer-intro{
        display: none;
    }
    
    .footer-nav{
        display: none;
    }

    .copyright-text{
        text-align: center;
    }
    
}


/* Tab Portrait Size to Tab Landscape Size (devices and browsers) */
@media only screen and (min-width:380px) and (max-width: 480px)  {
    .xs-margin{
        margin-left: 20px;
        margin-right: 20px;
    }
}

@media only screen and (min-width:480px) and (max-width: 600px)  {
    .xs-margin{
        margin-left: 70px;
        margin-right: 70px;
    }
}

@media only screen and (min-width:600px) and (max-width: 767px)  {
    .xs-margin{
        margin-left: 120px;
        margin-right: 120px;
    }
}

@media only screen and (min-width: 991px) {
    .margin-bottom-mob-30{
        margin-bottom: 0px;
    }

    .margin-bottom-mob-50{
        margin-bottom: 0px;
    }
}